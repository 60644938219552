<template>
  <LoginComponent />
</template>
<script>
import LoginComponent from "@/esf_utrecht_bankxl/core/components/auth/LoginComponent.vue";
export default {
  name: "Login",
  components: {
    LoginComponent,
  },
  metaInfo() {
    return {
      title: this.$t("login"),
    };
  },
};
</script>
